import { selectDropdown } from 'api/redux/DropdownReducer';
import { useGetLightLogoQuery } from 'api/redux/services/organizations.service';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { SponsorDropown } from './dropdowns/SponsorDropdown';
import { LogoSponsorDropdown } from './dropdowns/LogoSponsorDropdown';

export const SponsorLogo: FC = () => {
	const grants = useSelector(selectDropdown);
	const { currentSponsor } = grants.grants;
	const { data: logo } = useGetLightLogoQuery(currentSponsor.id);

	if (!logo) return <SponsorDropown />;

	return <LogoSponsorDropdown url={logo?.url} />;
};
